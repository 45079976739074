:root {
  --donda-gray: #a1a1a1;
  --donda-white: #d0d0da;
  --outline: 0px 0px 0 var(--donda-gray), -1px -1px 0 var(--donda-gray), 1px -1px 0 var(--donda-gray), -1px 1px 0 var(--donda-gray), 1px 1px 0 var(--donda-gray);
  --animation-duration: 15s;
  --gap: 1rem;
}

@font-face {
  font-family: Bebas Neue;
  src: url("BebasNeue-Regular.949de78a.ttf");
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Bebas Neue, sans-serif;
}

a {
  color: var(--donda-gray);
}

button {
  font-size: inherit;
  color: var(--donda-gray);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  text-decoration: underline;
}

a:hover, button:hover, button:disabled {
  text-decoration: none;
}

body {
  color: var(--donda-gray);
  width: 100%;
  background-color: #000;
}

.jail, .jail_home {
  opacity: 1;
  gap: var(--gap);
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

h1 {
  font-size: 200px;
  line-height: .9;
}

.jail_home {
  opacity: 1;
}

.donda__container {
  min-width: 100%;
  justify-content: space-around;
  gap: var(--gap);
  animation: hurricane var(--animation-duration) linear infinite;
  flex-shrink: 0;
  list-style-type: none;
  animation-play-state: paused;
  display: flex;
}

.jail:nth-child(2n) .donda__container {
  animation-direction: reverse;
}

.jail_home .donda__container {
  animation-play-state: running;
}

.donda__outline {
  color: #000;
  text-shadow: var(--outline);
  margin: 0 20px;
  display: inline-block;
}

h1 .counter {
  color: var(--donda-white);
}

.god__breathed {
  animation: moon 3s ease-in-out infinite alternate;
}

@keyframes moon {
  from {
    opacity: 1;
  }

  to {
    opacity: .3;
  }
}

@keyframes hurricane {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)) );
  }
}

h3.counter {
  color: var(--donda-white);
  text-align: center;
  margin: 0;
  font-size: 60px;
}

.main-nav {
  margin-top: 2vh;
}

.main-nav ol {
  list-style: none;
}

.main-nav ol li {
  margin-right: 10px;
  font-size: 5vh;
  display: inline-block;
}

.main-nav.stacked ol li {
  display: block;
}

@media screen and (max-width: 768px) {
  h1 {
    white-space: normal;
    font-size: 35vw;
    line-height: .9;
  }

  .main-nav ol li {
    font-size: 3vh;
  }
}

.preloader {
  width: 100%;
  height: 100vh;
  transform-origin: 100%;
  background-color: #000;
  justify-content: center;
  align-items: center;
  transition: transform .7s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.preloader__text {
  text-align: center;
  position: relative;
}

.preloader__text span {
  transition-property: transform;
  transition-duration: 0s;
  transition-timing-function: ease-in-out;
  display: block;
  transform: translateY(160px);
}

.preloader__text:before {
  content: "";
  height: 250px;
  color: #fff;
  width: auto;
  z-index: 10;
  background-color: #000;
  display: block;
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.90cdcf13.css.map */
